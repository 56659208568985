import React, {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  CallState,
  Experiment,
  ExperimentState,
  ExperimentStateWithCallState,
  ExperimentVariant,
} from "redmond";
import { fetchActiveExperiments } from "../api/v1/experiments/fetchExperiments";

export enum ActiveExperiments {
  FlightExchange = "c1-ops-selfserve-exchange",
  RequestTravelerEdit = "c1-ops-selfserve-request-edit-traveler-details",
  PriceFreeze = "c1-fintech-pricefreeze",
  DisruptionProtection24hRule = "c1-fintech-disruption-24-hours-rule",
  CFAR = "c1-fintech-cfar",
  SelfServeV3 = "c1-ops-selfserve-v3",
  SelfServeFlightForceCS = "c1-ops-selfserve-flight-force-customer-service",
  SelfServeFlightForceCSTrava = "c1-ops-selfserve-flight-trava-customer-service",
  SelfServeFlightTFCS = "c1-ops-selfserve-travelfusion-cancel",
  SelfServeHotelCancel = "c1-ops-selfserve-hotel-cancel",
  SelfServeHotelChange = "c1-ops-selfserve-hotel-change",
  SelfServeHomesCancel = "c1-ops-selfserve-homes-cancel",
  SelfServeHotelItemizedReceipt = "c1-ops-selfserve-hotel-itemized-receipt",
  SelfServeGroundCancel = "c1-ops-selfserve-ground-cancel",
  SelfServeGroundChange = "c1-ops-selfserve-ground-change",
  SelfServePremiumDisruptionAssistance = "c1-ops-self-serve-premium-disruption-assistance",
  SKCHNextDisplay = "c1-ops-skch-next-display",
  ABHideSupportBtn = "c1-ops-selfserve-exchange-ab",
  PRICE_DROP_CREDIT = "c1-fintech-price-drop-credit",
  PREFERRED_HOTEL_EXPERIMENT = "c1-marketplace-cot-preferred-hotel-merch",
  LODGING_PROMOTIONS = "HotelPromotion",
  PREMIER_COLLECTION_EXPERIMENT = "c1-marketplace-luxury-hotels-collection",
  HOTELS_CFAR = "c1-fintech-hotel-cfar",
  PREMIER_COLLECTION_CFAR = "c1-fintech-premier-collection-cfar",
  POST_BOOKING_OFFER_MY_TRIPS = "c1-fintech-disruption-air-postbooking-permanent-mytrips",
  HOTELS_PRICE_FREEZE = "c1-fintech-pf-hotel",
  THEBES_HACKER_FARES_IN_CAP1 = "ThebesHackerFaresInCap1",
  HOTEL_CFAR_REFUND_DISPLAY = "c1-fintech-hotel-cfar-refund-display",
  TRAVEL_SALE = "c1-marketplace-travel-sale",
  FlightExchangeUseFareRulesService = "c1-agency-use-exchange-fare-rules-service",
  FINTECH_CSAT = "c1-fintech-csat",
  HOTEL_CROSS_SELL_V3_EXPERIMENT = "c1-marketplace-hotel-cross-sell-v3",
  HOTELS_TAXES_AND_FEES_EXPERIMENT = "c1-marketplace-HotelsCaliforniaBill537",
  PACKAGES_EXPERIMENT = "c1-marketplace-packages",
  CACHE_HOTEL_TOKEN = "c1-marketplace-cache-hotel-token",
}

export enum Variant {
  AllOtherChanges = "all-other-changes",
}

// Default variants
export const CONTROL = "control";
export const AVAILABLE = "available";
export const DEFAULT_VARIANTS = [CONTROL, AVAILABLE] as const;
export type DefaultVariantType = typeof CONTROL | typeof AVAILABLE;

// Variants for HOTEL_CFAR_REFUND_DISPLAY_CONTROL
export const HOTEL_CFAR_REFUND_DISPLAY_CONTROL = "control";
export const HOTEL_CFAR_REFUND_DISPLAY_REFUND_DOLLAR_AMOUNT_RR =
  "refund-dollar-amount-RR";
export const HOTEL_CFAR_REFUND_DISPLAY_REFUND_DOLLAR_AMOUNT_ADDITIONAL_PLACES =
  "refund-dollar-amount-additional-places";
export const HOTEL_CFAR_REFUND_DISPLAY_80_PERCENT_REFUND_AMOUNT =
  "80-percent-refund-amount";
export const HOTEL_CFAR_REFUND_DISPLAY_NON_REF_PARTIAL = "non-ref-partial";
export const HOTEL_CFAR_REFUND_DISPLAY_VARIANTS = [
  HOTEL_CFAR_REFUND_DISPLAY_CONTROL,
  HOTEL_CFAR_REFUND_DISPLAY_REFUND_DOLLAR_AMOUNT_RR,
  HOTEL_CFAR_REFUND_DISPLAY_REFUND_DOLLAR_AMOUNT_ADDITIONAL_PLACES,
  HOTEL_CFAR_REFUND_DISPLAY_80_PERCENT_REFUND_AMOUNT,
  HOTEL_CFAR_REFUND_DISPLAY_NON_REF_PARTIAL,
] as const;
export type HotelCfarRefundDisplayVariantType =
  | typeof HOTEL_CFAR_REFUND_DISPLAY_CONTROL
  | typeof HOTEL_CFAR_REFUND_DISPLAY_REFUND_DOLLAR_AMOUNT_RR
  | typeof HOTEL_CFAR_REFUND_DISPLAY_REFUND_DOLLAR_AMOUNT_ADDITIONAL_PLACES
  | typeof HOTEL_CFAR_REFUND_DISPLAY_80_PERCENT_REFUND_AMOUNT
  | typeof HOTEL_CFAR_REFUND_DISPLAY_NON_REF_PARTIAL;

// Variants for c1-marketplace-cot-preferred-hotel-merch
export const PREFERRED_HOTELS_V1 = "v1-special-rates";
export const PREFERRED_HOTELS_VARIANT = [CONTROL, PREFERRED_HOTELS_V1] as const;
export type PreferredHotelsPropVariantType =
  | typeof CONTROL
  | typeof PREFERRED_HOTELS_V1;

// Variants for HotelPromotion
export const LODGING_PROMOTIONS_AVAILABLE = "Available";
export const LODGING_PROMOTIONS_VARIANTS = [
  CONTROL,
  LODGING_PROMOTIONS_AVAILABLE,
] as const;
export type LodgingPromotionsPropVariantType =
  | typeof CONTROL
  | typeof PREFERRED_HOTELS_V1;

// Variants for c1-marketplace-travel-sale
export const TRAVEL_SALE_LEAD_UP = "lead-up";
export const TRAVEL_SALE_ACTIVE = "active";
export const TRAVEL_SALE_VARIANTS = [
  CONTROL,
  TRAVEL_SALE_LEAD_UP,
  TRAVEL_SALE_ACTIVE,
] as const;
export type TravelSalePropVariantType =
  | typeof CONTROL
  | typeof TRAVEL_SALE_LEAD_UP
  | typeof TRAVEL_SALE_ACTIVE;

// Variants c1-marketplace-hotel-cross-sell-v3
export const HOTEL_CROSS_SELL_V3_VARIANT_1 = "dollar-offer";
export const HOTEL_CROSS_SELL_V3_VARIANT_2 = "percentage-offer";
export const HOTEL_CROSS_SELL_V3_VARIANTS = [
  CONTROL,
  HOTEL_CROSS_SELL_V3_VARIANT_1,
  HOTEL_CROSS_SELL_V3_VARIANT_2,
] as const;
export type HotelCrossSellV3VariantType =
  | typeof CONTROL
  | typeof HOTEL_CROSS_SELL_V3_VARIANT_1
  | typeof HOTEL_CROSS_SELL_V3_VARIANT_2;

// Variants for c1-marketplace-HotelsCaliforniaBill537
export const LEAST_DISRUPTION = "least-disruption";
export const BEST_COMPLIANCE = "best-compliance";
export const HOTELS_TAXES_AND_FEES_VARIANTS = [
  CONTROL,
  LEAST_DISRUPTION,
  BEST_COMPLIANCE,
] as const;
export type HotelsTaxesAndFeesVariantType =
  | typeof CONTROL
  | typeof LEAST_DISRUPTION
  | typeof BEST_COMPLIANCE;

// Variants for c1-marketplace-packages
export const SELF_FUNDED = "self-funded";
export const NOT_SELF_FUNDED = "not-self-funded";
export const PACKAGES_EXPERIMENT_VARIANTS = [
  CONTROL,
  SELF_FUNDED,
  NOT_SELF_FUNDED,
] as const;
export type PackagesExperimentVariantType =
  | typeof CONTROL
  | typeof SELF_FUNDED
  | typeof NOT_SELF_FUNDED;

export const APPROVALS_V2_VARIANTS = [CONTROL, "m2"] as const;

// Variants for c1-ops-self-serve-premium-disruption-assistance
export const SELF_SERVE_ENABLED = "SelfServeEnabled";
export const SELF_SERVE_DISABLED = "SelfServeDisabled";

export const SELF_SERVE_PREMIUM_DISRUPTION_ASSISTANCE_VARIANTS = [
  SELF_SERVE_ENABLED,
  SELF_SERVE_DISABLED,
] as const;

const defaultInitializer = (): ExperimentStateWithCallState => {
  return {
    experiments: [],
    trackingProperties: undefined,
    callState: CallState.NotCalled,
  };
};

export const ExperimentsContext = createContext<
  ExperimentStateWithCallState | undefined
>(undefined);

// readonly (together with const restriction below) ensures typeof supportedVariants[number]
// can be recognized by transpiler as an array of constants
// [string, ...string[]] ensures array is not empty.
export function getExperimentVariantCustomVariants<
  T extends readonly [string, ...string[]]
>(
  experiments: Array<Experiment>,
  experimentId: string,
  // List of supported variants. If the variant retreived is not recognized by the code, the code will fall back to the first variant.
  // Note the input list needs to be declared as const e.g. const DEFAULT_VARIANTS = [CONTROL, AVAILABLE] as const;
  supportedVariants: T
): (typeof supportedVariants)[number] {
  const experiment = experiments?.find((exp) => exp.id === experimentId);

  if (experiment) {
    if (supportedVariants.includes(experiment.variant)) {
      return experiment.variant;
    } else {
      return supportedVariants[0];
    }
  } else {
    return supportedVariants[0];
  }
}

export function getExperimentVariant(
  experiments: Array<Experiment>,
  experimentId: string
): DefaultVariantType {
  return getExperimentVariantCustomVariants(
    experiments,
    experimentId,
    DEFAULT_VARIANTS
  );
}

export function useExperiments(): ExperimentStateWithCallState {
  const ctx = useContext(ExperimentsContext);
  if (!ctx) throw new Error(`must be used within a ExperimentsProvider`);
  return ctx;
}

export function useExperiment(
  experimentId: string,
  target: string = ExperimentVariant.AVAILABLE
) {
  const { experiments } = useExperiments();
  if (!experiments) throw new Error("No experiments found in Context");
  const expVariant = getExperimentVariant(experiments, experimentId);
  return expVariant === target;
}

export function addTrackingProperties(
  trackingProperties: Map<string, string> | undefined,
  properties?: any
): unknown {
  const updatedProperties = properties ?? {};

  if (trackingProperties) {
    updatedProperties.experiments = Object.keys(trackingProperties).map(
      (ex) => `${ex}_${trackingProperties![ex]}`
    );
  }

  return properties;
}

type ExperimentsProviderProps = {
  initState?: ExperimentStateWithCallState;
};

const ExperimentsProvider: FC<ExperimentsProviderProps & PropsWithChildren> = ({
  initState = defaultInitializer(),
  children,
}) => {
  // TODO: use tracking reducers
  const [state, setState] = useState(initState);

  useEffect(() => {
    if (!initState?.experiments.length) {
      const fetchExperiments = async () => {
        setState({ ...state, callState: CallState.InProcess });
        await fetchActiveExperiments().then((result) => {
          setState({
            ...(result as ExperimentState),
            callState: CallState.Success,
          });
        });
      };
      fetchExperiments();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ExperimentsContext.Provider value={state}>
      {children}
    </ExperimentsContext.Provider>
  );
};

export default ExperimentsProvider;
